<template>
  <div>
    <News :site="3" />
  </div>
</template>

<script>
import News from "@/views/official-website/news/index.vue";

export default {
  components: {
    News,
  },
};
</script>

<style lang="less" scoped></style>
