<template>
  <div class="news-list-container">
    <div class="header" v-if="site === 0">
      <el-radio-group v-model="currentSite" @change="changeSite">
        <el-radio-button name="钱包官网" :label="1">钱包官网</el-radio-button>
        <el-radio-button name="博泉哈希" :label="2">博泉哈希</el-radio-button>
      </el-radio-group>
    </div>
    <el-row class="header" type="flex">
      <el-col :span="18" class="search-option">
        <el-input
          @keyup.enter.native="handleSearch"
          v-model="searchKey"
          class="search-input"
          placeholder
        ></el-input>
        <el-button
          style="margin: 0 8px"
          type="primary"
          @click="handleSearch"
          placeholder="请输入title"
          >搜索</el-button
        >
        <span style="margin: 0 20px">主题</span>
        <el-select v-model="categoryType" placeholder="请选择主题">
          <el-option label="全部" value="0">全部</el-option>
          <el-option
            v-for="category in categoryList.items"
            :key="category.id"
            :label="category.article_type_name"
            :value="category.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="create-option">
        <el-button type="primary" @click="handleCreate">添加文章</el-button>
      </el-col>
    </el-row>

    <div class="content">
      <el-table :data="newsList.items" border stripe>
        <el-table-column
          v-for="col in columns"
          :key="col.id"
          :prop="col.id"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
        ></el-table-column>
        <el-table-column label="Action" width="250" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleEdit(scope.row)">
              编辑
            </el-button>
            <el-button
              v-if="+scope.row.status >= 0"
              size="mini"
              type="warning"
              @click="handleOff(scope.row)"
            >
              下架
            </el-button>
            <el-button
              v-if="+scope.row.status < 0"
              size="mini"
              type="success"
              @click="handleOn(scope.row)"
            >
              上架
            </el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">
              删除
            </el-button>
            <el-button size="mini" type="primary" @click="handleSort(scope.row.id)">
              权重
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 20px">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="newsList.total"
          background
          @size-change="sizeChange"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      :title="'权重'"
      :visible.sync="showCreateDialog"
      width="30%"
      @close="showCreateDialog = false"
    >
      <el-form ref="form" :model="postForm" label-width="80px">
        <el-form-item label="权重">
          <el-input v-model="postForm.sort"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { editNews } from "@/api/news";

const SITE_TYPE = {
  1: "钱包官网",
  2: "博泉哈希",
  3: "恒境 NFT",
};

export default {
  props: {
    site: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      searchKey: "",
      categoryType: "0",
      currentPage: 1,
      pageSize: 20,
      postForm: {},
      showCreateDialog: false,
      columns: [
        {
          id: "id",
          label: "ID",
          width: 80,
        },
        {
          id: "type",
          label: "Theme",
          width: 100,
          formatter: (row) => {
            return row.article_type_name || row.type;
          },
        },
        {
          id: "title",
          label: "Title",
          width: 200,
        },
        {
          id: "sort",
          label: "权重",
          width: 80,
        },
        {
          id: "update_time",
          label: "Date",
          width: 180,
        },
        {
          id: "site",
          label: "Belong",
          formatter: (row) => {
            return SITE_TYPE[row.site];
          },
          width: 100,
        },
        {
          id: "status",
          label: "status",
          formatter: (row) => {
            return +row.status >= 0 ? "已上架" : "已下架";
          },
          width: 80,
        },
        {
          id: "cn_desc",
          label: "Brief Introduction",
        },
      ],
      currentSite: 1,
    };
  },
  computed: {
    ...mapGetters(["newsList", "categoryList"]),
  },
  watch: {
    categoryType() {
      this.getNewsList();
    },
  },
  mounted() {
    // 初始化
    if (this.site) {
      this.currentSite = this.site;
    }

    this.getNewsList();
    this.getCategory();
  },
  methods: {
    getNewsList() {
      const query = {};
      query.all = 1;
      query.site = this.currentSite;
      query.page = this.currentPage;
      query.page_size = this.pageSize;
      if (+this.categoryType) {
        query.type = this.categoryType;
      }
      if (this.searchKey) {
        query.keyword = this.searchKey;
      }
      this.$store.dispatch("news/getNewsList", query);
    },
    getCategory() {
      this.$store.dispatch("news/getCategoryList", {
        page: 1,
        size: 20,
        site: this.currentSite,
      });
    },
    changeSite(val) {
      this.currentPage = 1;
      this.pageSize = 30;
      this.getNewsList();
      this.getCategory();
    },
    handleEdit(data) {
      const url = this.generateUrlBySite(`/website/news/edit/${data.id}`);
      this.$router.push(url);
    },
    handleOff(data) {
      const { id, ...resData } = data;
      editNews({ ...resData, status: -1, article_id: id }).then(() => {
        this.$message({ message: "下架成功", duration: 3000, type: "success" });
        this.getNewsList();
      });
    },
    handleOn(data) {
      const { id, ...resData } = data;
      editNews({ ...resData, status: 0, article_id: id }).then(() => {
        this.$message({ message: "上架成功", duration: 3000, type: "success" });
        this.getNewsList();
      });
    },
    handleDelete(data) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("news/deleteNews", data.id);
      });
    },
    handleSort(id) {
      this.showCreateDialog = true;
      this.isEdit = true;
      this.postForm = {
        article_id: id,
        sort: this.sort,
      };
    },
    onSubmit() {
      editNews(this.postForm).then((data) => {
        if (+data.data.code) {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "error",
          });
          this.handleSuccess();
        } else {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "success",
          });
          this.handleSuccess();
        }
      });
    },
    handleSuccess() {
      this.showCreateDialog = false;
      this.postForm = {};
      this.isEdit = false;
      this.getNewsList();
    },
    handleCreate() {
      const url = this.generateUrlBySite("/website/news/create");
      this.$router.push(url);
    },
    generateUrlBySite(url) {
      if (this.site) {
        return `${url}?site=${this.site}`;
      }
      return url;
    },
    handleSearch() {
      this.getNewsList();
    },
    sizeChange(size) {
      this.pageSize = size;
      this.getNewsList();
    },
    currentChange(page) {
      this.currentPage = page;
      this.getNewsList();
    },
  },
};
</script>

<style lang="less" scoped>
.news-list-container {
  margin: 0;

  .header {
    margin: 20px;
  }

  .search-input {
    width: 300px;
  }

  .create-option {
    display: flex;
    justify-content: flex-end;
  }

  .content {
    padding: 0 20px 20px;
  }
}
</style>
